
import React from "react"

import WebappsConversationpageComponent from "../../components/webapps/components/conversationpage"

const SMSPage = ({location}) => {
	return <WebappsConversationpageComponent
			allowadd={false}
			location={location}
		/>

}


export default SMSPage;
